import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
// import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
// import Input from 'common/src/components/Input';
// import Container from 'common/src/components/UI/Container';
import Fade from 'react-reveal/Fade';

import { navigate } from 'gatsby';

import './contactsection2.css';

import axios from 'axios';
const ContactSection2 = ({ sectionWrapper, title, button, note, name }) => {
  // useEffect(() => {
  //     const x = window.location.href
  //     console.log(`x is ${x}`)
  // }, []);
  const path = name.fields.slug;
  const pathName = path.replace('/case-study/', '').slice(0, -1);
  console.log(pathName);
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
      navigate(`/thank-you/${pathName}`);
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: 'https://getform.io/f/7683b371-ada9-47cb-8ae8-307a6f2afc07',
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, 'Thank you for filling the form!', form);
      })
      .catch(r => {
        handleServerResponse(
          false,
          'Oops! the connection has been interrupted. Please try to submit again.',
          form
        );
      });
  };

  return (
    <Fade right cascade>
      <Box {...sectionWrapper}>
        <Text {...title} content="Please fill out the form" />
        <form onSubmit={handleOnSubmit}>
          <div className="form_fields">
            <input
              type="text"
              placeholder="First Name*"
              name="firstname"
              required
            />
            <input type="text" placeholder="Last Name" name="lastname" />
          </div>
          <div className="form_fields">
            <input
              type="email"
              placeholder="Business Email*"
              name="email"
              required
            />
            <input type="text" placeholder="Role*" name="role" required />
          </div>
          <div className="form_fields">
            <input type="text" placeholder="Company*" name="company" required />
            <input type="tel" placeholder="Contact Number" name="phone" />
          </div>

          <Button
            className="btn btn_tertiary"
            title="Download Now"
            type="submit"
            value="submit"
          />
          {serverState.status && (
            <p className={!serverState.status.ok ? 'errorMsg' : 'successMsg'}>
              {serverState.status.msg}
            </p>
          )}
          <div className="empty_space30" />
        </form>
      </Box>
    </Fade>
  );
};

ContactSection2.propTypes = {
  sectionWrapper: PropTypes.object,
  button: PropTypes.object,
  title: PropTypes.object,
  note: PropTypes.object,
};

ContactSection2.defaultProps = {
  sectionWrapper: {
    id: 'contact_section2',
    pt: 0,
    pb: 0,
    // pt: ['30px', '30px', '30px', '30px'],
    // pb: ['35px', '35px', '50px', '60px'],
    bg: '#f0f3f6',
  },
  button: {
    type: 'button',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
  note: {
    fontSize: ['13px', '14px', '15px', '15px', '15px'],
    color: '#5d646d',
    lineHeight: '1.87',
    textAlign: 'center',
  },
  title: {
    fontSize: ['20px', '20px', '24px', '24px', '24px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '30px',
    textAlign: 'center',
  },
};

export default ContactSection2;
